<template>
  <v-row
    no-gutters
    class="h-100"
    justify="space-between"
  >
    <v-col cols="12" offset="0" md="4" offset-md="4" class="d-flex flex-column justify-center align-center">
      <v-card
        class="w-100 rounded-lg"
      >
        <v-card-title>{{ $t('resetPassword.title') }}</v-card-title>
        <v-card-text>
          <v-form
            ref="loginForm"
            v-model="valid"
          >
            <v-row>
              <v-col
                cols="12"
                class="pb-0"
              >
                <v-text-field
                  v-model="form.password"
                  :append-icon="form.passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="form.passwordShow ? 'text' : 'password'"
                  name="input-10-1"
                  :label="$t('resetPassword.password')"
                  outlined
                  dense
                  single-line
                  validate-on-blur
                  :rules="passwordRules"
                  @click:append="form.passwordShow = !form.passwordShow"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                class="pt-0"
              >
                <CheckPasswordStrength
                  v-if="form.password.length > 3"
                  :value="form.password"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="form.confirmPassword"
                  :append-icon="form.confirmPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="form.confirmPasswordShow ? 'text' : 'password'"
                  name="input-10-1"
                  :label="$t('resetPassword.confirmPassword')"
                  outlined
                  dense
                  single-line
                  validate-on-blur
                  :rules="confrimPasswordRules"
                  @click:append="form.confirmPasswordShow = !form.confirmPasswordShow"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-4 mb-4">
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-btn color="info" outlined @click="generatePassword">
                {{ $t('resetPassword.generatePassword') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <div class="d-flex justify-end">
                <v-btn color="secondary" outlined class="mr-2" @click="$router.push({ name: 'Login' })">
                  {{ $t('resetPassword.cancel') }}
                </v-btn>
                <v-btn color="primary" :disabled="submitLoading" :loading="submitLoading" @click="submitHandle">
                  {{ $t('resetPassword.submit') }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import services from '@/services'
import { generateString } from '@/utils/helpers'
import CheckPasswordStrength from '@/components/CheckPasswordStrength'

export default {
  name: 'ResetPassword',
  components: {
    CheckPasswordStrength
  },
  data () {
    return {
      form: {
        password: '',
        passwordShow: false,
        confrimPassword: '',
        confirmPasswordShow: false
      },
      submitLoading: false,
      valid: true,
      passwordRules: [
        v => !!v || this.$t('validation.passwordRequired'),
        (v) => v.length >= 8 || this.$t('validation.passwordInvalidLength')
      ],
      confrimPasswordRules: [
        v => !!v || this.$t('validation.confirmPasswordRequired'),
        v => v === this.form.password || this.$t('validation.passwordsMustMatch')
      ]
    }
  },
  created () {
    this.logoutMobileDevice()
  },
  methods: {
    generatePassword () {
      const newPassword = `${generateString()}${generateString()}`
      this.form.password = newPassword
      this.form.confirmPassword = newPassword
    },
    async submitHandle () {
      this.$refs.loginForm.validate()
      if (!this.valid) {
        return
      }
      this.submitLoading = true
      try {
        await services.post('Authentication/SetNewPassword', {
          password: this.form.password,
          email: sessionStorage.getItem('email'),
          resetCode: sessionStorage.getItem('resetCode')
        })
        sessionStorage.removeItem('email')
        sessionStorage.removeItem('resetCode')
        sessionStorage.removeItem('expiryDate')
        await this.$router.push({ name: 'Login' })
      } catch (e) {}
      this.submitLoading = false
    },
    logoutMobileDevice () {
      if (this.$route.name !== 'ResetPasswordMobile') {
        return
      }
      if (this.$store.getters.isLoggedIn) {
        this.$store.dispatch('logout', true)
      }
      sessionStorage.setItem('email', this.$route.query.email)
      sessionStorage.setItem('resetCode', this.$route.query.resetCode)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
